import { $ } from "@olmokit/dom";
import {breakpoints, gridGutter } from "@olmokit/core/scss";
import {
  glide,
  Anchors,
  Autoplay,
  Breakpoints,
  Controls,
  LazyLoad,
  Swipe,
} from "@olmokit/core/glide";
import "@olmokit/core/glide/arrows";
import { BlogCard } from "components/BlogCard";
import "./index.scss";

/**
* Component: BlogSlider
*/

export default function slider() {

  BlogCard();
  
  const $container = $(".BlogSlider:slider");
  console.log(breakpoints.lg);
  const slider = glide(
    $container,
    {
      loop: true,
      // autoplay: 4500,
      killWhen: { above: breakpoints.lg },
      bound: true,
      breakpoints: {
        [breakpoints.xl]: {
          perView: 3,
          gap: 0,
          peek: { before: 0, after: 0 },
        },
        [breakpoints.lg]: {
          perView: 3,
          gap: 0,
          peek: { before: 0, after: 0 },
        },
        [breakpoints.md]: {
          perView: 1,
          gap: 0,
          peek: { before: 0, after: 0 },
        },
      },
    },
    { Anchors, 
      Autoplay, 
      Breakpoints, 
      Controls,
      LazyLoad, 
      Swipe }
      );
      
      return slider;
      
    }
