/**
* @file Route: home
*
* This file is compiled as a standalone entrypoint and it is included by
* default in its blade template.
* This is a "standard" route, it does not have a structure to follow, you are
* sure the DOM is always ready and the JS will only be executed in this route.
*/

import "layouts/main";
import { Header } from "components/Header";
import { HomeSlider } from "components/HomeSlider";
import slider from "components/BlogSlider";
import stand from "components/StandSlider";
import { replaceFragment } from "@olmokit/core/fragments";
import { FormContact } from "components/FormContact";
import {
    glide,
    Anchors,
    Autoplay,
    Controls,
    LazyLoad,
    Swipe,
    Fade,
} from "@olmokit/core/glide";
import "@olmokit/core/glide/arrows";
import "utils/blog.scss";
import "./index.scss";

console.log("Route home/index.js mounted.");

Header();
HomeSlider();
slider();
stand();

const glideOptions = {
    type: "slider",
    perView: 1,
    focusAt: 0,
    startAt: 0,
    autoplay: 6000,
    hoverpause: false,
    gap: 0,
    loop: true,
    animationDuration: 600,
    animationTimingFunc: "cubic-bezier(.43,.16,.44,1.01)",
};

const element = document.getElementsByClassName("home:slider");
if (element.length > 0) {
    const slider = glide(element[0], glideOptions);
    slider.mount({
        Swipe,
        LazyLoad,
        Anchors,
        Controls,
        Autoplay,
        Fade,
    });
}

replaceFragment("contactForm", "components.FormContact").then(() => FormContact());
