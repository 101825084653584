import { $ } from "@olmokit/dom";
import {breakpoints, gridGutter } from "@olmokit/core/scss";
import {
  glide,
  Anchors,
  Autoplay,
  Breakpoints,
  Controls,
  LazyLoad,
  Swipe,
} from "@olmokit/core/glide";
import "@olmokit/core/glide/arrows";
import { StandCard } from "components/StandCard";
import "./index.scss";

/**
* Component: StandSlider
*/

export default function stand() {

  StandCard();
  
  const $container = $(".StandSlider:slider");
  // console.log(breakpoints.lg);
  const slider = glide(
    $container,
    {
      loop: true,
      // autoplay: 4500,
      killWhen: { above: breakpoints.lg },
      bound: true,
      breakpoints: {
        [breakpoints.xl]: {
          perView: 3,
          gap: gridGutter,
          peek: { before: gridGutter / 2, after: gridGutter / 2 },
        },
        [breakpoints.lg]: {
          perView: 3,
          gap: gridGutter,
          peek: { before: gridGutter / 2, after: gridGutter / 2 },
        },
        [breakpoints.md]: {
          perView: 2,
          gap: gridGutter / 2,
          peek: { before: gridGutter / 2, after: gridGutter * 2 },
        },
        [breakpoints.sm]: {
          perView: 1,
          gap: gridGutter / 2,
          peek: { before: gridGutter / 2, after: gridGutter * 2 },
        },
      },
    },
    { Anchors, 
      Autoplay, 
      Breakpoints, 
      Controls,
      LazyLoad, 
      Swipe }
      );
      
      return slider;
      
    }
