import { $ } from "@olmokit/dom";
import FormsInputMaterial from "@olmokit/core/forms/input/material";
import "@olmokit/core/forms/checkbox";
import "./index.scss";

/**
* Component: FormNewsletter
*
*/
export function FormNewsletter() {
  
  console.log("FormNewsletter mounted.");
  
  FormsInputMaterial();
  
  const nlSubmit = $("#mc-embedded-subscribe");
  const nlEmail = $("#mce-EMAIL");
  const nlPrivacy = $("#gdpr_800");
  nlSubmit.addEventListener('click', function (event) {
    // event.preventDefault();
    if (nlEmail.classList.contains('notempty') && nlPrivacy.checked == true) {
      dataLayer.push({
        event: "form sent",
        form: "Form Newsletter",
      });
    }
  })
  
}
