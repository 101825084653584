import { $, $all, on, forEach } from "@olmokit/dom";
import "./index.scss";
// import { $ } from "@olmokit/dom";

/**
 * Component: StandCard
 */
export function StandCard() {

  console.log("StandCard mounted.");
  
  const cardImgs = $all(".StandCard:img");
  const cardBtns = $all(".StandCard:title");

  if (cardImgs) {
    for(let i=0; i < cardImgs.length; i++){
      on(cardImgs[i], "click", () => {
        let dest = cardImgs[i].getAttribute('data-href');
        window.location.href = dest;
      });
    }
  }
  if (cardBtns) {
    for(let i=0; i < cardBtns.length; i++){
      on(cardBtns[i], "click", () => {
        let dest = cardBtns[i].getAttribute('data-href');
        window.location.href = dest;
      });
    }
  }

}
